import React from "react";
import { graphql } from "gatsby";
import SelfLearnTemplate from "../../templates/self-learn";

const environment = {
  lang: "en",
  path: "/en/dutch-grammar",
  altHrefLang: [
    {
      lang: "nl",
      path: "/nederlandse-grammatica",
    },
    {
      lang: "en",
      path: "/en/dutch-grammar",
    },
  ],

  theme: "orange",
};

const SelfLearnEn = (props) => {
  return <SelfLearnTemplate {...props} environment={environment} />;
};

export default SelfLearnEn;

export const query = graphql`
  query GrammarItemsQueryEn {
    pills: allContentfulGrammarPill(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: [difficulty, title], order: [ASC, ASC] }
    ) {
      edges {
        node {
          title
          slug
          difficulty
          node_locale
          contentful_id
          __typename
          categories {
            title
          }
          relatedExercises {
            title
          }
          relatedQuiz {
            ...QuizFragment
          }
        }
      }
    }
  }
`;
